<template>
  <v-container>
    <onboarding />
  </v-container>
</template>

<script>
const Onboarding = () => import(
  /* webpackChunkName: "onboarding" */
  '@/modules/onboarding')

export default {
  components: { Onboarding }
}
</script>
