<template>
  <v-container
    v-if="company"
    :class="{'pa-0':$vuetify.breakpoint.smAndDown}"
  >
    <v-stepper
      v-model="step"
      non-linear
      alt-labels
      class="rounded-lg"
      outlined
    >
      <v-tooltip
        bottom
        :disabled="!isDirty"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-stepper-header
              class="flex-nowrap"
            >
              <template
                v-for="({title, complete}, index) in steps"
              >
                <v-stepper-step
                  :key="title"
                  :editable="!isDirty"
                  :complete="complete"
                  edit-icon="$complete"
                  :step="index + 1"
                  :class="{'mobile-stepper':$vuetify.breakpoint.smAndDown}"
                >
                  {{ title }}
                </v-stepper-step>
                <v-divider
                  v-if="index !== steps.length - 1"
                  :key="index"
                  :class="{'mobile-divider':$vuetify.breakpoint.smAndDown}"
                />
              </template>
            </v-stepper-header>
          </div>
        </template>
        <span>
          Bitte Speichern Sie zuerst Ihre Änderungen
        </span>
      </v-tooltip>

      <v-stepper-items>
        <v-stepper-content
          step="1"
        >
          <company-data
            class="px-4"
            v-bind="companyDataProps"
            is-onboarding
            @nextStep="handleNextStep"
            @dirty="handleDirty"
            @missingData="(value) => handleMissingData(value, 0)"
          />
        </v-stepper-content>

        <v-stepper-content
          v-if="isFeatureActive(featureNames.CONTRACT)"
          step="2"
        >
          <billing-data
            class="px-4 pt-2"
            v-bind="billingDataProps"
            is-onboarding
            @nextStep="handleNextStep"
            @dirty="handleDirty"
            @missingData="(value) => handleMissingData(value, 1)"
          />
        </v-stepper-content>

        <v-stepper-content
          :step="getStep('Ansprechpartner')"
        >
          <contacts
            class="px-4"
            v-bind="contactDataProps"
            is-onboarding
            @nextStep="handleNextStep"
            @dirty="handleDirty"
            @missingData="(value) => handleMissingData(value, getStep('Ansprechpartner') - 1)"
          />
        </v-stepper-content>

        <v-stepper-content
          :step="getStep('Publikation')"
        >
          <publication
            class="px-4"
            v-bind="publicationDataProps"
            is-onboarding
            @nextStep="handleNextStep"
            @dirty="handleDirty"
            @missingData="(value) => handleMissingData(value, getStep('Publikation') - 1)"
          />
        </v-stepper-content>

        <v-stepper-content
          v-if="getStep('Ratgeber')"
          :step="getStep('Ratgeber')"
        >
          <Landingpages
            class="px-4"
            :count-of-guides="countOfGuides"
            v-bind="landingpageDataProps"
            @nextStep="handleNextStep"
            @dirty="handleDirty"
            @missingData="(value) => handleMissingData(value, getStep('Ratgeber') - 1)"
          />
        </v-stepper-content>

        <v-stepper-content
          v-if="getStep('E-Mail')"
          :step="getStep('E-Mail')"
        >
          <EmailValidation
            class="px-4"
            :email="company.ambassador.email"
            :company-name="company.name"
            @nextStep="handleNextStep"
            @missingData="(value) => handleMissingData(value, getStep('E-Mail') - 1)"
          />
        </v-stepper-content>

        <v-stepper-content
          :step="getStep('Überprüfung')"
        >
          <submit
            class="px-4"
            :is-complete="isComplete"
            :company-id="company.id"
            :missing-data="missingData"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import CompanyData from '@/modules/company/CompanyData'
import BillingData from '@/modules/company/BillingData'
import Contacts from '@/modules/company/Contacts'
import Publication from '@/modules/company/Publication'
import Submit from './Submit.vue'
import COMPANY from '@/modules/company/queries/Company.gql'
import COMPANY_PRODUCTS from '@/modules/company/queries/CompanyProducts.gql'
import Landingpages from './Landingpages.vue'
import EmailValidation from './EmailValidation.vue'
import { isBasicUser } from '@/lib/productTypes'
import featureMixin from '@/mixins/feature'

const PREVIEWS = {
  ATV1: 'https://static.bottimmo.com/assets/onboarding/previews/A4C_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  CHV1: 'https://static.bottimmo.com/assets/onboarding/previews/C0X_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  CHV2: 'https://static.bottimmo.com/assets/onboarding/previews/C3BE_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  DV1: 'https://static.bottimmo.com/assets/onboarding/previews/D4AL_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  DV2: 'https://static.bottimmo.com/assets/onboarding/previews/D6EM_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  DV3: 'https://static.bottimmo.com/assets/onboarding/previews/D8QT_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  DV4: 'https://static.bottimmo.com/assets/onboarding/previews/D91RL_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  REMAX: 'https://static.bottimmo.com/assets/onboarding/previews/R13PO_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  VOBA: 'https://static.bottimmo.com/assets/onboarding/previews/V99F7_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf'
}

export default {
  components: { CompanyData, BillingData, Contacts, Publication, Submit, Landingpages, EmailValidation },
  mixins: [featureMixin],
  data () {
    return {
      step: -1,
      isDirty: false,
      missingData: {},
      products: []
    }
  },
  computed: {
    countOfGuides () {
      return this.products.filter((product) => product === 'landingpage').length + 1
    },
    isBasic () {
      return isBasicUser(this.$auth.user)
    },
    hasEmailValidation () {
      return this.getFeature(this.featureNames.HOPPERMATION)?.isActive
    },
    isComplete () {
      return this.steps?.slice(0, -1).every(({ complete }) => complete)
    },
    steps () {
      const steps = [
        { route: 'company', title: 'Firmendaten', complete: this.missingData?.Firmendaten?.length === 0 },
        { route: 'payment', title: 'Rechnungsdaten', complete: this.missingData?.Rechnungsdaten?.length === 0, disabled: !this.isFeatureActive(this.featureNames.CONTRACT) },
        { route: 'contact', title: 'Ansprechpartner', complete: this.missingData?.Ansprechpartner?.length === 0 },
        { route: 'publication', title: 'Publikation', complete: this.missingData?.Publikation?.length === 0 },
        { route: 'guide', title: 'Ratgeber', complete: this.missingData?.Ratgeber?.length === 0, disabled: !this.isBasic },
        { route: 'email-validation', title: 'E-Mail', complete: this.missingData['E-Mail']?.length === 0, disabled: !this.hasEmailValidation },
        { route: 'validation', title: 'Überprüfung', complete: this.missingData['Überprüfung']?.length === 0 }
      ]
      return steps
        .filter(({ disabled }) => !disabled)
        .map((step, index) => ({ ...step, step: index + 1 }))
    },
    companyDataProps () {
      const { id: companyId, name, address, links } = this.company
      return { companyId, name, address, links }
    },
    billingDataProps () {
      const { id: companyId, country, billing, customerNumber, address, name } = this.company
      return {
        companyId,
        country,
        address,
        name,
        customerNumber,
        billing: {
          ...billing,
          address: billing.address || {}
        }
      }
    },
    contactDataProps () {
      return { companyId: this.company.id }
    },
    publicationDataProps () {
      const {
        id: companyId, slogan, ambassador, name, links, address, mailProvider,
        images: { team, logo, office, region },
        variant,
        theme,
        isReferenceCustomer
      } = this.company
      ambassador.image = ambassador.image || {}
      ambassador.autograph = ambassador.autograph || {}
      theme.colors.primary = theme.colors.primary || '#000000'
      theme.colors.secondary = theme.colors.secondary || '#000000'
      const previewLink = PREVIEWS[variant]
      return {
        companyId,
        slogan,
        ambassador,
        name,
        links,
        address,
        mailProvider,
        previewLink,
        theme,
        isReferenceCustomer,
        images: {
          team: team || {},
          logo: logo || {},
          office: office || {},
          region: region || {}
        }
      }
    },
    performanceDataProps () {
      const {
        id: companyId,
        contactPersons,
        performance = {
          facebook: { zip: [], onboarding: {} },
          google: { zip: [] }
        }
      } = this.company
      const getContactPerson = (type) => contactPersons.find((contact) => contact.type === type) || { address: {} }
      return {
        companyId,
        contactFacebook: getContactPerson('FACEBOOK'),
        contactGoogle: getContactPerson('GOOGLE'),
        performance,
        products: this.products
      }
    },
    landingpageDataProps () {
      const {
        id: companyId,
        variant,
        country,
        bookedLandingpageSlugs
      } = this.company
      return {
        companyId,
        variant,
        country,
        bookedLandingpageSlugs
      }
    }

  },
  watch: {
    step: {
      immediate: true,
      handler (step) {
        const stepIndex = this.steps.findIndex(step => step.route === this.$route.params.step) + 1
        if (stepIndex !== step && step !== -1) {
          const route = this.steps[step - 1].route
          this.$router.push('/onboarding/' + route)
        }
      }
    }
  },
  created () {
    this.step = this.steps.findIndex(step => step.route === this.$route.params.step) + 1
  },
  methods: {
    getStep (title) {
      return this.steps.find((step) => step.title === title)?.step
    },
    handleNextStep (value = 1) {
      const nextStep = this.step + value
      this.step = nextStep < 1 ? 1 : nextStep
    },
    handleDirty (value) {
      this.isDirty = value
    },
    handleMissingData (missingData, step) {
      this.missingData = { ...this.missingData, [this.steps[step].title]: missingData }
    }
  },
  apollo: {
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    },
    products: {
      query: COMPANY_PRODUCTS,
      skip () {
        return !this.isFeatureActive(this.featureNames.CONTRACT)
      },
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>

// No SCOPE to take affect inside vuetify elements
<style>
.mobile-stepper{
  max-width: 50px;
}

.mobile-divider{
  max-width: 15px;
}
</style>
