<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        xl="6"
        offset-xl="3"
        md="8"
        offset-md="2"
      >
        <v-card
          class="mt-16 ma-6 pa-12"
          elevation="4"
        >
          <h2 class="py-6">
            Vielen Dank für Ihre Angaben und Dateien.
          </h2>
          Um sicherzustellen, dass alles korrekt ist, durchläuft Ihr Onboarding eine manuelle Prüfung.
          Wir informieren Sie, sobald diese erfolgreich abgeschlossen wurde.
          Ab dann stehen Ihnen auch alle Tools, Landingpages, Ratgeber, Checklisten und Werbemittel zur Verfügung.
          <h4
            v-if="hasDedicatedSupport"
            class="py-6"
          >
            Ihr {{ readablePlatformName }} Customer Success Team.
          </h4>
          <h4
            v-else
            class="py-6"
          >
            Bei Fragen wenden Sie sich bitte an Ihr Support-Team.
          </h4>

          <client-advisor :company="company" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import COMPANY_BASE from '../queries/CompanyBase.gql'
import UPDATE_COMPANY_STATE from '../queries/UpdateCompanyState.gql'
import ClientAdvisor from '@/components/ClientAdvisor.vue'
import brandingMixin from '@/mixins/branding'
import featureNames from '@/lib/featureNames'

export default {
  components: { ClientAdvisor },
  mixins: [brandingMixin],
  apollo: {
    company: {
      query: COMPANY_BASE,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  },
  computed: {
    hasDedicatedSupport () {
      return this.$features.feature(featureNames.APP_BRANDING).config?.hasDedicatedSupport
    }
  },
  async mounted () {
    await this.$apollo.mutate({
      mutation: UPDATE_COMPANY_STATE,
      variables: {
        input: {
          companyId: this.$auth.user.companyId,
          event: 'ONBOARDED'
        }
      }
    })
    this.$router.push({
      path: '/dashboard'
    })
  }
}
</script>
